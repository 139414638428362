<template>
  <a-layout class='index animated fadeIn'>
    <a-card :bordered='false'>
      <a-tabs @change="callback">
        <a-tab-pane key="1" tab="代理商">
          <agentList></agentList>
        </a-tab-pane>
        <a-tab-pane key="2" tab="品牌商">
          <brandList></brandList>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </a-layout>
</template>
<script>
import { ref, onMounted,inject,reactive, toRefs } from "vue";
import { message } from "ant-design-vue";
import agentList from './components/agentList';
import brandList from './components/brandList';
export default {
  components: {agentList,brandList },
  setup(props) {
    const state = reactive({

    });
    const $router = inject('$router')
    const $Modal = inject('$Modal')
    
    onMounted(() => {
    });
    return {
      ...toRefs(state)
    };
  },
};
</script>